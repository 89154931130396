<template>
	<div class="additional-apps-container" @click="(evt) => (edit && !popoverOpen) ? stopEdit(evt) : null">
		<div class="header-container">
			<p v-if="!popoverOpen" class="header">My apps</p>
			<div :class="['edit-icon', { editing: edit }]"
				@click="(evt) => !edit ? startEdit(evt) : popoverOpen ? cancelAction(evt) : openEditModal(evt)">
				<img v-if="!edit" src="../../images/Edit.svg" />
				<div v-else>{{ popoverOpen ? edit ? 'Done' : 'Cancel' : 'New app' }}</div>
			</div>
		</div>
		<div ref="appsContainer" class="apps-container">
			<AdditionalApp v-bind:key="index" v-for="(app, index) in apps" :app="app" :edit="edit && !popoverOpen"
				@click="(evt) => handleClick(evt, app, index)" />
		</div>
		<Popover v-if="popoverOpen" @click="(evt) => evt.stopPropagation()" :add-app="addApp" :delete-app="deleteApp"
			:updating="updating" :close-popover="closePopover" :edit-index="editIndex" :apps="apps" />
	</div>
</template>

<script>
import AddIcon from "../../images/Add.svg";
import Popover from "./Popover.vue";
import AdditionalApp from './AdditionalApp.vue';

export default {
	name: "AdditionalApps",
	data() {
		return {
			edit: false,
			popoverOpen: false,
			apps: [],
			updating: false,
			error: null,
			AddIcon: AddIcon,
			editIndex: null,
		};
	},
	components: {
		Popover,
		AdditionalApp,
	},
	watch: {
		'apps.length'(newLength) {
			if (newLength > 11) {
				this.$nextTick(() => {
					this.handleScroll();
				});
			}
		}
	},
	mounted() {
		this.$refs.appsContainer.addEventListener("scroll", this.handleScroll);
		const savedApps = localStorage.getItem("myApps");
		if (savedApps) {
			this.apps = JSON.parse(savedApps);
		}
		this.$nextTick(() => {
			this.handleScroll(); // Call handleScroll immediately after mount
		});
	},
	unmounted() {
		if (this.$refs.appsContainer) {
			this.$refs.appsContainer.removeEventListener("scroll", this.handleScroll);
		}
	},
	methods: {
		cancelAction(evt) {
			evt.stopPropagation();
			this.popoverOpen = false;
			this.updating = false;
			this.editIndex = null;
		},
		closePopover() {
			this.popoverOpen = false;
			this.updating = false;
			this.editIndex = null;
		},
		handleClick(evt, app, index) {
			evt.stopPropagation();
			if (this.edit) {
				this.editIndex = index;
				this.updating = true;
				this.popoverOpen = true;
			} else {
				if (window.vuplex) {
					this.sendMessage(app.url);
				} else {
					window.addEventListener("vuplexready", () => this.sendMessage(app.url));
				}
			}
		},
		sendMessage(url) {
			window.vuplex.postMessage({ url: url });
		},
		stopEdit(evt) {
			evt.stopPropagation();
			this.editIndex = null;
			this.edit = false;
		},
		startEdit(evt) {
			evt.stopPropagation();
			this.editIndex = null;
			this.edit = !this.edit;
		},
		addApp(newApp) {
			this.apps.push({ ...newApp });
			localStorage.setItem("myApps", JSON.stringify(this.apps));
			this.popoverOpen = false;
			this.editIndex = null;
			this.updating = false;
		},
		deleteApp() {
			this.apps.splice(this.editIndex, 1);
			localStorage.setItem("myApps", JSON.stringify(this.apps));
			this.popoverOpen = false;
			this.editIndex = null;
			this.updating = false;
		},
		openEditModal(evt) {
			evt.stopPropagation();
			this.popoverOpen = true;
		},
		handleScroll() {
			const container = this.$refs.appsContainer;
			const apps = container.querySelectorAll(".app");
			apps.forEach((app) => {
				const rect = app.getBoundingClientRect();
				const containerRect = container.getBoundingClientRect();
				let distance = 0;
				let refactoredTop = containerRect.top + 100;
				let refactoredBottom = containerRect.bottom - 20;

				if (rect.top < refactoredTop) {
					distance = refactoredTop - rect.top;
				} else if (rect.bottom > refactoredBottom) {
					distance = rect.bottom - refactoredBottom;
				}

				const blurAmount = Math.min(20, distance / 10);
				const opacity = Math.max(0.5, 1 - blurAmount / 20);

				app.style.filter = `blur(${blurAmount}px)`;
				app.style.opacity = `${opacity}`
			});
		},
	},
};
</script>

<style scoped lang="scss">
.additional-apps-container {
	width: 656px;
	height: 572px;
	background-color: #605d57;
	padding: 0 32px;
	position: relative;

	.header-container {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 24px;
		z-index: 2;

		.header {
			font-family: Inter;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			text-align: center;
			margin: 10px;
			color: #ffffff;
		}

		.edit-icon {
			height: 44px;
			padding: 10px;
			border-radius: 500px;
			background-color: #ffffff14;
			position: absolute;
			right: 24px;
			top: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #ffffff;
			font-family: Inter;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			transition: background-color 300ms ease-out;

			&.editing {
				padding: 10px 16px;
			}

			&:hover {
				background-color: #FFFFFF29;
				cursor: pointer;
			}

			&:active {
				background-color: #FFFFFF3D;
			}
		}
	}

	.apps-container {
		padding-top: 116px;
		padding-bottom: 32px;
		height: 572px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: flex-start;
		row-gap: 42px;
		column-gap: 40px;
		overflow-y: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		padding-left: 16px;
		padding-right: 16px;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}
</style>
