import Timer from '../components/widgets/timers/Timer.vue';
import Stopwatch from '../components/widgets/timers/Stopwatch.vue';

import { createRouter, createWebHistory } from 'vue-router'
import Keyboard from "../components/keyboard/Keyboard.vue";
import KeyboardSuggestions from '../components/keyboard/KeyboardSuggestions.vue';
import Landing from "../components/Landing.vue";
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
import PageNotFound from '../components/PageNotFound.vue';
import FAQPage from '../components/FAQPage.vue';
import Calculator from '../components/widgets/calculator/Calculator.vue';
import TodoList from '../components/widgets/todo/TodoList.vue';
import NoteComponent from '../components/widgets/notes/Note.vue';

//Clocks
import Clock from '../components/widgets/clocks/Clock.vue';
import SimpleClock from '../components/widgets/clocks/simple/SimpleClock.vue';
import TallClock from '../components/widgets/clocks/TallClock.vue';
import FlowerClock from '../components/widgets/clocks/flower/FlowerClock.vue';
import BridgeClock from '../components/widgets/clocks/bridge/BridgeClock.vue';

import BoldClock from '../components/widgets/clocks/BoldClock.vue';
import ModernClock from '@/components/widgets/clocks/simple/ModernClock.vue';
import DigitalClock from '@/components/widgets/clocks/simple/DigitalClock.vue';
import WovenClock from '@/components/widgets/clocks/simple/WovenClock.vue';
import FlowerClock2 from '@/components/widgets/clocks/flower/FlowerClock2.vue';
import FlowerClock3 from '@/components/widgets/clocks/flower/FlowerClock3.vue';
import BridgeClock2 from '@/components/widgets/clocks/bridge/BridgeClock2.vue';
import BridgeClock3 from '@/components/widgets/clocks/bridge/BridgeClock3.vue';
import AdditionalApps from '@/components/additional-apps/AdditionalApps.vue';


const routes = [
    { path: '/', component: Landing},
    { path: '/privacy-policy', component:PrivacyPolicy},
    { path: '/faq', component:FAQPage},

    //apps

    //Calculator
    { path: '/calculator', component: Calculator},
    //Timers
    { path: '/timer', component: Timer },
    { path: '/stopwatch', component: Stopwatch },

    { path: '/keyboard', component: Keyboard},
    { path: '/keyboard-sug', component: KeyboardSuggestions },
    { path: '/todo', component: TodoList},
    { path: '/note', component: NoteComponent},

    //
    { path: '/additional-apps', component: AdditionalApps },

    //clocks
    { 
        path: '/clock', 
        component: Clock,
        redirect: { path: "/clock/simple" }, // redirect property 
        children: [
            {
                path:'simple',
                component:SimpleClock
            },
            {
                path:'modern',
                component:ModernClock
            },
            {
                path:'digital',
                component: DigitalClock
            },
            {
                path:'woven',
                component: WovenClock
            },
            {
                path:'tall',
                component:TallClock
            },
            {
                path:'bold',
                component:BoldClock
            },
            {
                path:'flower',
                component:FlowerClock
            },
            {
                path:'flower2',
                component:FlowerClock2
            },
            {
                path:'flower3',
                component:FlowerClock3
            },
            {
                path:'bridge',
                component:BridgeClock
            },
            {
                path:'bridge2',
                component:BridgeClock2
            },
            {
                path:'bridge3',
                component:BridgeClock3
            },
        ]
    },

    { path: "/:pathMatch(.*)*", component: PageNotFound }


]

let router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;
  