<template>
	<div :class="['todo-container', {'todo':selectedList !== null}]">
        <div v-if="selectedList != null" class="selected-todo">
            <div class="header">
                <img rel="preload" src="./images/back.svg" @click="selectedList = null"/>
                <input class="todo-title" @change="save" v-model="selectedList.name" placeholder="New to-do list" />
                <img rel="preload" src="./images/plus.svg" @click="addNew" />
            </div>
            <div class="list-of-todos">
                <div class="todo-element" :class="{ 'fade-out': todo.checked }" v-bind:key="todo" v-for="todo in selectedList.todos">
                    <div @click="() => updateTodo(todo)">
                        <div v-if="todo.checked" class="check">
                            <img rel="preload" class="checkmark" src="./images/checked.svg"/>
                        </div>
                        <div v-else class="uncheck">
                            <img rel="preload" class="uncheckmark" src="./images/unchecked.svg"/>
                        </div>
                    </div>
                    <textarea
                        @input="(evt) => autoResize(evt)"
                        v-model="todo.name"
                        :placeholder="todo.placeholder"
                        class="auto-textarea"
                        rows="1"
                        @blur="save"
                    >
                    </textarea>
                </div>
            </div>
        </div>
        <div class="list" v-else>
            <div class="all-todos">
                <div @click="() => selectList(list)" class="list-details" v-bind:key="list.id" v-for="list in lists">
                    <div class="title-row">
                        <p class="list-length">{{ list.todos.length }}</p>
                        <img rel="preload" src="./images/delete.svg" @click="(evt) => removeList(list, evt)"/>
                    </div>
                    <p>{{ list.name ? list.name : "New to-do list"}}</p>
                </div>
                <button class="add-button" @click="createNewList">
                    <div>
                        <img rel="preload" src="./images/plus.svg" />
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

	export default {
		name: 'TodoListComponent',
		data() {
			return {
                lists: [],
                selectedList: null
			}
		},
        mounted() {
            this.lists = localStorage.getItem('todo') ? JSON.parse(localStorage.getItem('todo')) : [];
            this.autoResize();
        },
		methods: {
            createNewList() {
                let newList = {
                    id:this.lists.length+1, 
                    name:null, 
                    todos: [
                        {id:0, name: null, checked:false, placeholder:"New reminder"}
                        ]
                    };
                this.lists.push(newList)
                this.selectedList = newList;
                this.save();

                this.$nextTick(() => {
                    // Resize each textarea based on its initial content if there is any
                    document.querySelectorAll('.auto-textarea').forEach(textarea => {
                        textarea.style.height = 'auto';  // Reset height
                        textarea.style.height = textarea.scrollHeight + 'px';  // Set to scrollHeight
                    });
                });
            },
            selectList(list) {
                this.selectedList = list;

                this.$nextTick(() => {
                    // Resize each textarea based on its initial content if there is any
                    document.querySelectorAll('.auto-textarea').forEach(textarea => {
                        textarea.style.height = 'auto';  // Reset height
                        textarea.style.height = textarea.scrollHeight + 'px';  // Set to scrollHeight
                    });
                });
            },
            addTodo() {
                this.selectedList.todos.push({id:this.selectedList.todos.length+1, name: null, checked:false, placeholder:"New reminder"});
                this.save();
            },
            deleteList(evt, id) {
                evt.stopPropagation();
                let newList = this.lists.filter(list => list.id !== id);
                this.lists = newList;
                this.save();
            },
            changeListName(name, id) {
                this.selectedList.name = name;
                this.lists.map(list => {
                    if(list.id === id) {
                        list.name = name
                    }
                });
                this.save();
            },
            updateTodo(todo) {
                this.selectedList.todos.map((elem) => todo.id === elem.id ? elem.checked = !elem.checked : null)
                if(todo.checked) {
                    setTimeout(() => {
                        let newData = this.selectedList.todos.filter((elem) => todo.id !== elem.id);
                        this.selectedList.todos = newData;
                        this.save();
                    }, 1300)
                }
            },
            addNew() {
                this.selectedList.todos.push({id:this.selectedList.todos.length+1, name: null, checked:false, placeholder:"New reminder"});
                this.save();
            },
            save() {
                let data = JSON.stringify(this.lists);
                localStorage.setItem('todo', data);
            },
            removeList(list, evt) {
                evt.stopPropagation();
                let newData = this.lists.filter(x => x.id !== list.id);
                this.lists = newData;
                this.save();
            },
            autoResize(event) {
                if(event) {
                    const textarea = event.target;
                    if(textarea) {
                        textarea.style.height = 'auto';  // Reset the height
                        textarea.style.height = textarea.scrollHeight + 'px';  // Set new height                    
                    }
                }
            }
		},
		computed: {

		}
	}
</script>

<style scoped lang="scss">
    .todo-container {
        width: 400px;
        height: 504px;
        background-color: hsl(216, 17%, 6%);
        padding: 32px;

        textarea {
            resize: none;
            border: none;
            outline: none;
            background-color: transparent;
            padding: 5px;

            height: 44px;

            color: white;

            font-family: 'Inter';
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;
            text-align: left;

        }

        input {
            background-color: transparent;
            border: none;
            box-shadow: none;
            outline: none;
            height: 40px;
            padding: 5px;

            color: white;

            font-family: 'Inter';
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;
            text-align: left;
        }

        img {
            &:hover {
                background-color: #FFFFFF1F;
;
                border-radius: 99px;
            }
        }

        .fade-out {
            opacity: 0;
            transition: opacity 0.3s ease-out 1s;
        }

        &.todo {
            justify-content: unset;
            padding: 24px;

            .selected-todo {
                display: flex;
                flex-direction: column;
                gap: 27px;
                width: 100%;
                height: 100%;

                .header {
                    display: flex;
                    gap: 8px;
                    align-items: center;

                    .todo-title {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }


                    input {
                        width: 100%;

                        &::placeholder {
                            color: #FFFFFF4D;
                        }
                    }

                }

                .list-of-todos {
                    overflow: auto;

                    input {
                        &::placeholder {
                            color: #FFFFFF4D;
                        }
                    }

                    .todo-element {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding: 12px 0 12px 0;
                        border-bottom: 1px solid #222427;
                        gap: 8px;

                        img {
                            &:hover {
                                background-color: unset;
                            }
                        }

                        .todo-name {
                            width: 100%;
                            height: 44px;
                        }

                        .checked, .uncheck {
                            height: 44px;
                        }

                    }
                }

                .add-button {
                    align-self: center;
                }
            }
        }

        p {
            margin: 0;
            font-family: 'Inter';
            font-weight: 500;
            line-height: 24px;
            font-size: 16px;
            color: white;
            text-overflow: ellipsis;
            overflow: hidden;

        }

        .list {
            overflow: scroll;
            height: 100%;
            width: 100%;

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */



            .empty-state {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 24px;
            }

            .all-todos {
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 12px;
                justify-content: flex-start;
                flex-wrap: wrap;

                .list-details {
                    width: 160px;
                    height: 100px;
                    border-radius: 16px;
                    background-color: #222427;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    padding: 8px 12px 12px 12px;

                    .title-row {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        img {
                            align-self: flex-start;
                            display: none;

                            &:hover {
                                background-color: #484A4D;
                            }
                        }
                    }

                    .list-length {
                        font-family: "Inter";
                        font-size: 32px;
                        font-weight: 500;
                        line-height: 48px;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: #323437;

                        .title-row {
                            img {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        
        .add-button {
            width: 160px;
            height: 100px;
            padding: 28px 0px 28px 0px;
            gap: 8px;
            border-radius: 16px;
            border: 2px solid #16181B;
            background-color: transparent;

            display: flex;
            align-items: center;
            justify-content: center;

            div {
                width: 44px;
                height: 44px;
                border-radius: 500px;
                background-color: #222427;

                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:hover {
                cursor: pointer;
            }

        }

    }
</style>
