<template>
	<div
		class="note-container"
		:style="{ backgroundColor }"
		@mouseover="showButton = true"
		@mouseleave="resetHover"
	>
		<textarea
			:style="{ backgroundColor }"
			@input="updateValue($event.target.value)"
			@focus="closePickers"
			v-model="textValue"
			placeholder="Start jotting away..."
		></textarea>
		<transition>
			<div class="color-picker" v-if="showButton">
				<div class="color-picker-button" @click="colorPickerOpen = !colorPickerOpen">
					<img v-if="!colorPickerOpen" src="./images/open.svg" alt="open"/>
					<img v-else src="./images/close.svg" alt="close"/>
				</div>
				<transition>
					<div v-if="colorPickerOpen" class="colors">
						<div
                            v-bind:key="index"
							v-for="(color, index) in colors"
							@click="changeColor(color)"
							class="color"
							:style="{ backgroundColor: color }"
						></div>
					</div>
				</transition>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'NoteComponent',
	data() {
		return {
			backgroundColor: '#FFFFFF',
			colors: ['#FFFFFF', '#FFF59B', '#FF9E9E', '#B5E0FF', '#9CFFB2', '#E3A7FF'],
			colorPickerOpen: false,
			textValue: '',
			showButton: false,
			id: null,
			notes: []
		}
	},
	mounted() {
		try {
			this.notes = JSON.parse(localStorage.getItem('notes')) || [];
		} catch (e) {
			this.notes = [];
		}

		const queryId = this.$route.query.id;
		if (queryId != null && this.notes[queryId]) {
			this.id = queryId;
			this.textValue = this.notes[this.id].text || '';
			this.backgroundColor = this.notes[this.id].color || '#FFFFFF';
		} else {
			this.id = this.notes.length;
			this.notes.push({ text: '', color: '#FFFFFF' });
			this.saveNotes();
			this.$router.push({ path: '/note', query: { id: this.id } });
		}
	},
	methods: {
		changeColor(color) {
			this.backgroundColor = color;
			if (!this.notes[this.id]) {
				this.notes[this.id] = { text: this.textValue, color };
			} else {
				this.notes[this.id].color = color;
			}
			this.saveNotes();
		},
		updateValue(value) {
			this.textValue = value;
			if (!this.notes[this.id]) {
				this.notes[this.id] = { text: value, color: this.backgroundColor };
			} else {
				this.notes[this.id].text = value;
			}
			this.saveNotes();
		},
		saveNotes() {
			try {
				localStorage.setItem('notes', JSON.stringify(this.notes));
			} catch (e) {
				console.error('Error saving notes to localStorage:', e);
			}
		},
		closePickers() {
			this.showButton = false;
			this.colorPickerOpen = false;
		},
		resetHover() {
			this.showButton = false;
			this.colorPickerOpen = false;
		}
	},
}
</script>

<style scoped lang="scss">
.note-container {
	width: 400px;
	height: 400px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	gap: 18px;
	textarea {
		width: 336px;
		height: 274px;
		resize: none;
		border: none;
		outline: none;
		font-family: Kalam;
		font-size: 26px;
		font-weight: 400;
		line-height: 39px;
		text-align: left;
	}
	.color-picker {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
		.color-picker-button {
			height: 44px;
		}
		.colors {
			display: flex;
			flex-direction: row;
			gap: 4px;
			background-color: #0C0E11;
			border-radius: 999px;
			padding: 8px;
			.color {
				width: 28px;
				height: 28px;
				border-radius: 999px;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}
</style>
