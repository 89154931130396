<template>
	<!-- Apply 'no-jiggle' class when edit is false -->
	<div class="additional-app app" :class="{ 'no-jiggle': !edit }" :style="edit ? jiggleStyle : null">
		<div class="icon-container">
			<div :class="['favicon-wrapper', 'icon', app.icon.includes('hsl') ? 'hsl-icon' : '']"
				:style="{ backgroundColor: app.icon.includes('hsl') ? app.icon : null }">
				<img v-if="!app.icon.includes('hsl')" :src="app.icon" />
				<p v-else>{{ app.title ? app.title[0].toUpperCase() + '' + app.title[1].toUpperCase() : '' }}</p>
			</div>
		</div>
		<div class="text-container">{{ app.title }}</div>
	</div>
</template>

<script>
export default {
	name: 'AdditionalApp',
	props: {
		app: {
			type: Object,
			required: true,
		},
		edit: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		jiggleStyle() {
			return {
				animation: `${this.randomJiggle} ${this.randomDuration}s infinite ease-in-out alternate`,
			};
		},
		randomJiggle() {
			return Math.random() < 0.5 ? 'jiggleA' : 'jiggleB';
		},
		randomDuration() {
			// Generates a random duration between 0.10 and 0.15 seconds
			return (Math.random() * (0.15 - 0.10) + 0.10).toFixed(2);
		},
	},
};
</script>

<style>
@keyframes jiggleA {
	0% {
		transform: rotate(-2deg);
	}

	100% {
		transform: rotate(2deg);
	}
}

@keyframes jiggleB {
	0% {
		transform: rotate(2deg);
	}

	100% {
		transform: rotate(-2deg);
	}
}
</style>

<style scoped lang="scss">
.app {
	width: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	transition: transform 0.3s ease-in-out;

	// Default hover effect (used when edit is true)
	&:hover {
		cursor: pointer;
		transform: scale(1.2);
	}

	// When jiggle is off (edit is false), override hover & active styles
	&.no-jiggle {
		&:hover {
			transform: none;
		}

		// Apply transitions to the icon wrapper
		.favicon-wrapper {
			transition: transform 200ms ease-out;
		}

		// Hover: move icon up by 4px
		&:hover .favicon-wrapper {
			transform: translateY(-4px);
		}

		// Pressed: scale icon down to 90%
		&:active .favicon-wrapper {
			transform: scale(0.9);
		}
	}

	.icon-container {
		border-radius: 12px;
		display: flex;
		justify-content: center;

		.favicon-wrapper {
			width: 72px;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 20px;

			&.hsl-icon {

				box-shadow: 0px 2px 5px 0px #00000021,
					0px 9px 9px 0px #0000001C,
					0px 20px 12px 0px #00000012,
					0px 35px 14px 0px #00000005,
					0px 55px 15px 0px #00000000;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 20px;
				border: 0;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
				outline: 1.5px solid #FFFFFF26;

				p {
					font-family: Inter;
					font-weight: 500;
					font-size: 32px;
					line-height: 48px;
					letter-spacing: 1px;
					text-align: center;
					margin: 0;
					color: white;
				}
			}
		}

		img {
			width: 72px;
			height: 72px;
			border-radius: 20px;
			box-shadow: 0px 4px 8px 0px #00000029;
		}
	}

	.text-container {
		font-family: Inter;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0.3px;
		line-height: 17px;
		text-align: center;
		color: rgba(255, 255, 255, 0.6);
		height: 17px;
		overflow: hidden;
		white-space: nowrap;
		width: 110px;
		text-overflow: ellipsis;
	}
}
</style>