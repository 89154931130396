<template>
    <transition name="popover" appear>
        <div class="popover-container">
            <div ref="popover" class="add-app-popover">
                <div class="interactions">
                    <div class="app popover-icon">
                        <div v-if="!newApp.icon" class="icon-container add">
                            <div class="favicon-wrapper">
                                <img :src="AddIcon" />
                            </div>
                        </div>
                        <div v-else class="icon-container">
                            <div :class="['favicon-wrapper', 'icon', newApp.icon.includes('hsl') ? 'hsl-icon' : '']" :style="{backgroundColor:newApp.icon.includes('hsl') ? newApp.icon : null}">
                                <img v-if="!newApp.icon.includes('hsl')" :src="newApp.icon" />
								<p v-else>{{ (newApp.title && (newApp.title[0] && newApp.title[1])) ? newApp.title[0].toUpperCase() + '' + newApp.title[1].toUpperCase() : ''}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="input-group popover-input">
                        <div class="input-width-icon">
                            <!-- eslint-disable-next-line -->
                            <input
                                placeholder="Type your URL here"
                                @keyup.enter="searchUrl"
                                v-model="newApp.url"
                                type="text"
                            />
                            <button class="small-search-icon" @click="searchUrl" tabindex="0">
                                <img :src="SearchIcon" />
                            </button>
                        </div>
						<div class="input-title" v-if="isLoaded">
							<input
                                v-model="newApp.title"
                                type="text"
                            />
						</div>
                        <button
                            class="primary-button"
                            :class="{ delete: updating }"
                            :disabled="isButtonDisabled()"
                            @click="updating ? deleteApp() : onSubmit()"
                        >
                            {{ updating ? 'Remove app' : 'Add app' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import axios from "axios";
    import AddIcon from "../../images/Add.svg";
    import SearchIcon from "../../images/Search.svg";

    /* eslint-disable */
    export default {
        name: "AdditionalApps",
        props: ["updating", "deleteApp", "closePopover", "addApp", "apps", "editIndex"],
        data() {
            return {
                AddIcon: AddIcon,
                SearchIcon: SearchIcon,
                newApp: {
                    url: null,
                    icon: null,
                    title: null,
                },
                // Added reactive property for button disable control
                isLoaded: false,
            };
        },
        mounted() {
            if (this.editIndex != null) {
                this.newApp = this.apps[this.editIndex];
                this.isLoaded = true;
            }
        },
        beforeUnmount() {
            if (this.editIndex != null) {
                //this.onSubmit();
            }
        },  
        methods: {
            onSubmit() {
                this.addApp(this.newApp);
                // Reset newApp and isLoaded after submission
                this.newApp = {
                    url: null,
                    icon: null,
                    title: null,
                };
                this.isLoaded = false;
            },
			checkImageTransparency(imageUrl, callback) {
				const img = new Image();
				// Enable CORS so we can access pixel data (ensure the server supports it)
				img.crossOrigin = "Anonymous";

				img.onload = function () {
					// Create a canvas with the same dimensions as the image
					const canvas = document.createElement("canvas");
					canvas.width = img.width;
					canvas.height = img.height;
					const ctx = canvas.getContext("2d");

					// Draw the image onto the canvas
					ctx.drawImage(img, 0, 0);

					// Get image data (all pixel data)
					const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
					const pixels = imageData.data;
					let hasNonTransparentPixel = false;

					// Check every pixel's alpha channel (every 4th value starting at index 3)
					for (let i = 3; i < pixels.length; i += 4) {
						if (pixels[i] !== 0) {
							hasNonTransparentPixel = true;
							break;
						}
					}
					// Call the callback with the result:
					// hasNonTransparentPixel === true means the image has some color pixels.
					// false means the image is completely transparent.
					callback(hasNonTransparentPixel);
				};

				img.onerror = function () {
					console.error("Error loading image");
					callback(null);
				};

				// Set the source to start loading the image
				img.src = imageUrl;
			},
            async searchUrl() {
                try {
                    const validatedUrl = this.validateUrl(this.newApp.url);

                    if (validatedUrl) {
                        let url =
                            "https://cdn.brandfetch.io/" +
                            this.newApp.url +
                            "/w/400/h/400?c=1idGNkVr5atdUSdAlxW";
                        let response = await axios.get(url);

                        if (response.data) {
							this.checkImageTransparency(url, (hasColor) => {
								if (hasColor) {
									this.newApp.icon = url;
								} else {
									const randomMultipleOf10 = () => (Math.floor(Math.random() * 36) + 1) * 10;
									let hslVal = "hsl(" + randomMultipleOf10() + " 50% 80%)"
									this.newApp.icon = hslVal;
								}
							});

							try {
								await this.getPageTitle(validatedUrl);
							}
							catch(err) {
								this.newApp.title = this.newApp.url;
								this.isLoaded = true;
							}
                        }
                    } else {
                        console.log("Failed to validate url");
                        // TODO: generate alternative assets for the app if URL validation fails
                    }
                } catch (e) {
                    console.log("Failed to get brand icon", e);
                }
            },
            validateUrl(urlString) {
                try {
                    // If the URL doesn't start with a protocol, add 'https://'
                    if (!/^(?:f|ht)tps?\:\/\//.test(urlString)) {
                        urlString = "https://" + urlString;
                    }
                    // If URL constructor succeeds, it's a valid URL
                    new URL(urlString);
                    return urlString;
                } catch (e) {
                    return false;
                }
            },
            async getPageTitle(validatedUrl) {
                const encodedUrl = encodeURIComponent(validatedUrl);
                const baseUrl = window.location.href.includes("localhost")
                    ? "http://localhost:3001"
                    : "";
                const proxyUrl = `${baseUrl}/proxy?url=${encodedUrl}`;
                const response = await axios.get(proxyUrl);
                const html = response.data;

                // Use DOMParser to parse the HTML string
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, "text/html");

                // Extract the page title
                const titleEl = doc.querySelector("title");
                const title = titleEl ? titleEl.innerText.trim() : validatedUrl;
                this.newApp.title = title;
                this.isLoaded = true;
            },
            isButtonDisabled() {
                if(this.updating) {
                    return false;
                }
                return !this.isLoaded || !((this.newApp.title && this.newApp.title.length) && (this.newApp.url && this.newApp.url.length))
            },
        },
    };
</script>

<style scoped lang="scss">
    /* Transition for the popover overlay */
    .popover-container {
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(40px);
        background: rgba(0, 0, 0, 0.24);
        top: 0;
        left: 0;
        z-index: 1;
    }

    /* Animate the overlay entering/leaving over 400ms */
    .popover-enter-active,
    .popover-leave-active {
        transition: all 600ms ease-out;
    }

    .popover-enter-from,
    .popover-leave-to {
        opacity: 1;
        backdrop-filter: blur(0px);
        background: rgba(0, 0, 0, 0);
    }

    .popover-enter-to {
        opacity: 1;
        backdrop-filter: blur(40px);
        background: rgba(0, 0, 0, 0.24);
    }

    /* --- Fade in UI for inner elements --- */
    /* For the icon and input group, use the Vue 3 enter classes */
    .popover-enter-from .popover-icon {
        transform: translateY(-16px);
        opacity: 0;
    }

    .popover-enter-from .popover-input {
        transform: translateY(16px);
        opacity: 0;
    }

    .popover-enter-active .popover-icon,
    .popover-enter-active .popover-input {
        transition: opacity 300ms ease-out, transform 300ms ease-out;
    }

    .popover-enter-to .popover-icon,
    .popover-enter-to .popover-input {
        transform: translateY(0);
        opacity: 1;
    }

    /* --- Existing Styles --- */
    .add-app-popover {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 272px;
        height: 232px;
        z-index: 2;

        .interactions {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }

        .input-group {
            display: flex;
            flex-direction: column;
            gap: 18px;

            .input-width-icon {
                position: relative;

                button {
                    width: 28px;
                    height: 28px;
                    gap: 8px;
                    border-radius: 500px;
                    background-color: #ffffff14;
                    padding: 6px;
                    outline: none;
                    border: none;
                    position: absolute;
                    right: 8px;
                    top: 7px;
                    transition: background-color 300ms ease-out;

                    &:hover {
                        cursor: pointer;
                        background-color: #ffffff3d;
                    }
                }
            }
        }

        .app {
            width: 136px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            transition: transform 0.3s ease-in-out;

            .icon-container {
                border-radius: 12px;
                display: flex;
                align-content: center;
                justify-content: center;
                padding: 12px;

                .favicon-wrapper {
                    width: 80px;
                    height: 80px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

					&.hsl-icon {

						box-shadow: 0px 2px 5px 0px #00000021,
									0px 9px 9px 0px #0000001C,
									0px 20px 12px 0px #00000012,
									0px 35px 14px 0px #00000005,
									0px 55px 15px 0px #00000000;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 20px;
						border: 0;
						background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
                        outline: 1.5px solid #FFFFFF26;

						p {
							font-family: Inter;
							font-weight: 500;
							font-size: 32px;
							line-height: 48px;
							letter-spacing: 1px;
							text-align: center;
							margin:0;
                            color: white;
						}
					}

                    &.icon {
                        img {
                            border-radius: 20px;
                            border-width: 1.35px;
                            border: 1.5px solid;
                            border-image-source: linear-gradient(
                                156.52deg,
                                rgba(255, 255, 255, 0.4) 2.12%,
                                rgba(255, 255, 255, 0.0001) 39%,
                                rgba(255, 255, 255, 0.0001) 54.33%,
                                rgba(255, 255, 255, 0.1) 93.02%
                            );
                            box-shadow: 0px 4px 8px 0px #00000029;
                        }
                    }
                }

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                }
            }

            .text-container {
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.3px;
                color: rgba(255, 255, 255, 0.6);
                height: 17px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 137px;
            }
        }

        input {
            width: 273px;
            height: 44px;
            padding: 10px 16px;
            border-radius: 1000px;
            background-color: #00000014;
            outline: none;
            border: none;
            color: #ffffff;
            box-shadow: 0px 1.5px 9px 0px #00000029 inset,
                0px 1.5px 2px 0px #00000052 inset,
                0px -1.5px 2px 0px #ffffff29 inset,
                0px -0.5px 2px 0px #ffffff52 inset;
            transition: background-color 300ms ease-out;

            font-family: Inter;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0px;

            &:disabled {
                color: #ffffff;
            }

            &:active {
                background-color: #00000052;
            }

            &:focus {
                outline: none;
                border: none;
                background-color: #00000033;
            }
        }
    }

    .primary-button {
        width: 100%;
        height: 44px;
        padding: 0px 16px;
        border-radius: 500px;
        outline: none;
        border: none;
        background-color: #ffffff;
        color: #0c0e11;

        font-family: Inter;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;


        &.delete {
            background-color: #ff3b30;
            color: #ffffff;

            &:hover {
                background-color: #ff3b30cc;
            }
            &:active {
                background-color: #ff3b3099;
            }
        }

        &:disabled {
            background: rgba(255, 255, 255, 0.06);
            color: #ffffff29;

            &:hover {
                cursor: default;
                background: rgba(255, 255, 255, 0.06);
                color: #ffffff29;
            }
        }

        &:hover {
            background-color: #ffffffcc;
            cursor: pointer;
        }

        &:active {
            background-color: #ffffff99;
            cursor: pointer;
        }
    }
</style>
